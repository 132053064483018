//COMMON
export let q = document.querySelector.bind(document);
export let qa = document.querySelectorAll.bind(document);

let width = window.innerWidth > 0 ? window.innerWidth : screen.width;

export const easyHTTP = {
  async post(url, body) {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    if (typeof body == "object" && body.constructor == Object) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(body);
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body,
      });
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  },
  async put(url, body) {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    if (typeof body == "object" && body.constructor == Object) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(body);
    }
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers,
        body,
      });
      return await response.json();
    } catch (err) {
      console.log(err);
    }
  },
  async get(url) {
    try {
      const response = await fetch(url);
      const resData = await response.json();
      return resData;
    } catch (error) {
      console.log(error);
    }
  },
  async get_text(url) {
    try {
      const response = await fetch(url);
      const resData = await response.text();
      return resData;
    } catch (error) {
      console.log(error);
    }
  },
  async get_HTML(url, body) {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    if (typeof body == "object" && body.constructor == Object) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(body);
    }
    try {
      const response = await fetch(url, {
        method: "GET",
        headers,
        body,
      });
      const dom_elemet = string_to_HTML(await response.text());
      return dom_elemet;
    } catch (error) {
      console.log(error);
    }
  },

  // Make an HTTP DELETE Request
  async delete(url) {
    try {
      const response = await fetch(url, {
        method: "DELETE",
      });
      let resData = await response.json();
      return resData;
    } catch (error) {
      console.log(error);
    }
  },
};

const string_to_HTML = function (str) {
  if (!window.DOMParser) {
    let dom = document.createElement("div");
    dom.innerHTML = str;
    return dom.div;
  }

  let parser = new DOMParser();
  let doc = parser.parseFromString(str, "text/html");
  return doc.body.firstChild;
};

export const toggle_elem = (selector) => {
  q(selector).classList.toggle("visible");
};

export let replace_dom_element = (replace, new_item) => {
  replace.parentNode.replaceChild(new_item, replace);
};

export const Store = {
  get_items: function (items_name) {
    try {
      let items = localStorage.getItem(items_name);
      if (items) items = JSON.parse(items);
      return items ?? [];
    } catch (error) {
      return [];
    }
  },

  add_item: function (item, index) {
    let requested_storage_key = Object.keys(item)[0];
    let value_to_store = Array.isArray(Object.values(item)[0])
      ? Object.values(item)[0]
      : [Object.values(item)[0]];

    try {
      const items = Store.get_items(requested_storage_key);
      if (index && items.length > 0) {
        if (index == 0 && value_to_store[0].id == items[0].id) {
          items.splice(index, 1, ...value_to_store);
        } else {
          items.splice(index, 0, ...value_to_store);
        }
      } else {
        items.push(...value_to_store);
      }
      localStorage.setItem(requested_storage_key, JSON.stringify(items));
    } catch (error) {
      console.log(error);
    }
  },

  remove_item: function (item) {
    let items_arr = Store.get_items(Object.keys(item)).filter((el) => el._id !== item._id);
    localStorage.setItem(Object.keys(item)[0], JSON.stringify(items_arr));
  },

  clear: function (item) {
    localStorage.setItem(item, JSON.stringify([]));
  },

  set_item: function (item) {
    try {
      localStorage.setItem(Object.keys(item)[0], JSON.stringify(Object.values(item)[0]));
    } catch (error) {
      console.log(error);
    }
  },
};

export const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";
